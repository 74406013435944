import React,{useEffect,useState,useRef} from "react";

// Components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Media,
    Col,
    Container,
    Row
} from "reactstrap";
import styles from "../style/HelpCenter/helpcenter.module.css"
import {Send} from "react-feather";
import {toast} from 'react-toastify';
import helpCenter from '../../../assets/img/logo/helpCneter.svg'

// Services
import AuthService from "../../../services/auth.service";

toast.configure()
const HelpCenter = () => {
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email , setEmail ] = useState("");
    const [NatureOfEnquiry , setNatureOfEnquiry ] = useState("");
    const [Comments, setComments] = useState("");
    

    const handleFirstName = (e) => {
        setFirstName(e.target.value);
    };
    const handleLastName = (e) => {
        setLastName(e.target.value);
    };

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };
    const onChangeNatureOfEnquiry = (e) => {
        setNatureOfEnquiry(e.target.value);
    };
    const onChangeComments = (e) => {
        setComments(e.target.value);
    };

    const SubmitEvent = () => {
        if(FirstName == "" || LastName == "" || Email =="" || NatureOfEnquiry==""){
            toast.error('Kindly fill all the fields!!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }else{
            AuthService.SendHelpCenterEmail(FirstName,LastName,Email,NatureOfEnquiry,Comments).then((response)=>{
                if(response.data.Status == 'success'){
                    toast.success('Sent successfully', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setNatureOfEnquiry("");
                    setComments("");
                }else{
                    toast.error(response.data.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                }
            })
        }
    }

    return (
        <Container fluid className="p-8 pt-2">
            <p className={styles.title}>Help Center</p>
            <Card >
                <Row style={{fontSize:"14px"}}>
                    <Col md='7' style={{padding:"2rem"}}>
                            <p className={styles.Toptitle}>Please provide additional supporting comments.</p>
                            <div className="">
                                    <input name="inf_form_xid" type="hidden" value="52c9b3d4283f2414b3de30019edc8cf0" />
                                    <input name="inf_form_name" type="hidden" value="Help Center" />
                                    <input name="infusionsoft_version" type="hidden" value="1.70.0.527541" />
                                    <div className="infusion-field">
                                        <label htmlFor="FirstName" className={styles.color_92929D}>First Name*</label>
                                        <input id="FirstName"  value={FirstName} onChange={handleFirstName} className={styles.commoninput} name="FirstName"  placeholder="Enter First Name..." type="text" required/>
                                    </div>
                                    <div className="infusion-field">
                                        <label htmlFor="LastName" className={styles.color_92929D}>Last Name*</label>
                                        <input id="LastName" className={styles.commoninput} name="LastName" value={LastName} onChange={handleLastName} placeholder="Enter Last Name..." type="text" required/>
                                    </div>
                                    <div className="infusion-field">
                                        <label htmlFor="Email" className={styles.color_92929D}>Email Address*</label>
                                        <input id="Email" className={styles.commoninput} name="Email" value={Email} onChange={onChangeEmail} placeholder="Enter Email Address..." type="text" required/>
                                    </div>
                                    <div className="infusion-field" style={{flexDirection: "column"}} value={NatureOfEnquiry} onChange={onChangeNatureOfEnquiry}>
                                        <label htmlFor="NatureOfEnquiry" className={styles.color_92929D}>What is the primary nature of enquiry?*</label>
                                        <div className="infusion-radio" >
                                            <div className="options-container" >
                                                <span className={styles.commonoption}>
                                                    <input checked={NatureOfEnquiry == 'Navigating or logging into the website'} id="inf_option_Whatistheprimarynatureofenquiry_11626" style={{marginTop:"0.4rem"}} name="NatureOfEnquiry" className="mt-0" type="radio" value="Navigating or logging into the website" required/>
                                                    <label htmlFor="inf_option_Whatistheprimarynatureofenquiry_11626" style={{lineHeight:"20px"}} className={styles.color_92929D}>Navigating or logging into the
                                                        website</label>
                                                </span>
                                                <span className={styles.commonoption}>
                                                    <input checked={NatureOfEnquiry == 'Taking the assessments and accessing my results'} id="inf_option_Whatistheprimarynatureofenquiry_11628" style={{marginTop:"0.4rem"}} name="NatureOfEnquiry" className="mt-0" type="radio" value="Taking the assessments and accessing my results" required/>
                                                    <label htmlFor="inf_option_Whatistheprimarynatureofenquiry_11628" style={{lineHeight:"20px"}} className={styles.color_92929D}>Taking the assessments and accessing
                                                        my results</label>
                                                </span>
                                                <span className={styles.commonoption}>
                                                    <input checked={NatureOfEnquiry == 'Other'} id="inf_option_Whatistheprimarynatureofenquiry_11771"
                                                        name="NatureOfEnquiry" className="mt-0" type="radio" value="Other" style={{marginTop:"0.4rem"}}/>
                                                    <label htmlFor="inf_option_Whatistheprimarynatureofenquiry_11771" style={{lineHeight:"20px"}} className={styles.color_92929D}>Other</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                {/* <div className="col-sm-6" style={{paddingLeft:"2rem"}}>
                                    <input name="inf_form_xid" type="hidden" value="52c9b3d4283f2414b3de30019edc8cf0" />
                                    <input name="inf_form_name" type="hidden" value="Help Center" />
                                    <input name="infusionsoft_version" type="hidden" value="1.70.0.527541" />
                                    <div className="infusion-field">
                                        <label htmlFor="FirstName" className={styles.color_92929D}>First Name*</label>
                                        <input id="FirstName"  className={styles.commoninput} name="FirstName" placeholder="Enter First Name..." type="text" required/>
                                    </div>
                                    <div className="infusion-field">
                                        <label htmlFor="LastName" className={styles.color_92929D}>Last Name*</label>
                                        <input id="LastName" className={styles.commoninput} name="LastName" placeholder="Enter Last Name..." type="text" required/>
                                    </div>
                                    <div className="infusion-field">
                                        <label htmlFor="Email" className={styles.color_92929D}>Email Address*</label>
                                        <input id="Email" className={styles.commoninput} name="Email" placeholder="Enter First Name..." type="text" required/>
                                    </div>
                                </div>
                                <div className="col-sm-6" style={{paddingLeft:"2rem"}}>
                                    <div className="infusion-field" style={{flexDirection: "column"}}>
                                        <label htmlFor="NatureOfEnquiry" className={styles.color_92929D}>What is the primary nature of enquiry?*</label>
                                        <div className="infusion-radio" >
                                            <div className="options-container" >
                                                <span className={styles.commonoption}>
                                                    <input id="inf_option_Whatistheprimarynatureofenquiry_11626" style={{marginTop:"0.4rem"}} name="NatureOfEnquiry" className="pt-1" type="radio" value="11626" required/>
                                                    <label htmlFor="inf_option_Whatistheprimarynatureofenquiry_11626" style={{lineHeight:"20px"}} className={styles.color_92929D}>Navigating or logging into the
                                                        website</label>
                                                </span>
                                                <span className={styles.commonoption}>
                                                    <input id="inf_option_Whatistheprimarynatureofenquiry_11628" style={{marginTop:"0.4rem"}} name="NatureOfEnquiry" type="radio" value="11628" required/>
                                                    <label htmlFor="inf_option_Whatistheprimarynatureofenquiry_11628" style={{lineHeight:"20px"}} className={styles.color_92929D}>Taking the assessments and accessing
                                                        my bar-graph results</label>
                                                </span>
                                                <span className={styles.commonoption}>
                                                    <input id="inf_option_Whatistheprimarynatureofenquiry_11771"
                                                        name="NatureOfEnquiry" type="radio" value="11771" />
                                                    <label htmlFor="inf_option_Whatistheprimarynatureofenquiry_11771" style={{lineHeight:"20px"}} className={styles.color_92929D}>Other</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="infusion-field" style={{flexDirection: "column"}}>
                                <label htmlFor="Comments" style={{textAlign: 'center'}}>Please provide additional supporting comments.</label>
                                <textarea cols="24" id="Comments" name="Comments" className={styles.textareaStyle}
                                    placeholder="Comments..." rows="5" value={Comments} onChange={onChangeComments}></textarea>
                            </div>
                            <div>
                                <div>&nbsp;</div>
                            </div>
                            <div className="infusion-submit" style={{textAlign: 'center'}}>
                                <button  id="recaptcha_52c9b3d4283f2414b3de30019edc8cf0" className={styles.sendBtn +' infusion-recaptcha'} onClick={SubmitEvent}>Send<Send size={15}style={{marginLeft:"0.3rem"}} /></button>
                            </div>
                    </Col>
                    <Col md="5">
                        <div style={{padding:"2rem",paddingLeft:'0',height:'100%'}} className={styles.Toptitle}>
                            <div className={styles.leftContent}>
                                <h2>Frequently Asked Questions</h2>
                                <p style={{fontSize:"16px"}}>Discover the answers you seek! <a href="https://skillsgapanalysis.com/wp-content/uploads/2023/07/Academy_of_Procurement_FAQs_SGA_v2.pdf" target="_blank">Click here</a> to explore our comprehensive FAQ page and find solutions to your inquiries.</p>
                                <img src={helpCenter} alt="" className="mt-3" style={{width:'100%'}}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            
            </Card>
        </Container>
    );
};


export default HelpCenter;
