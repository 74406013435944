import React, { useEffect, useState, useRef } from "react";

// Services
import assessmentService from "../../../services/assessment.service.js";
import AuthService from "../../../services/auth.service.js";

// Components
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Filter, Settings } from "react-feather";
import { Button, Modal } from "react-bootstrap";
import { setInterval } from "timers";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

// Assets
import Watch from "../../../assets/img/icons/watch.png";

/*
* Time Based assessment view
*
* This view is responsible for displaying assessment questions & recording the answers
*
* TODO: Combine non time based & time based to a single component
*
* */
const QuizView = (props) => {
    sessionStorage.removeItem("ReSubmitQuestionKey");
    sessionStorage.removeItem("ReviewAssessmentKey");

    var currentUser = AuthService.getCurrentUser();
    var userId = currentUser.id;
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }

    if (props.location.state == null || props.location.state == undefined) {
        props.history.push({
            pathname: "/Assessments",
        });
        window.location.reload();
    }
    if (props.history.action == "POP") {
        var isKeyPresent = JSON.parse(sessionStorage.getItem("AssessmentQuizKey"));
        if (isKeyPresent == null) {
            props.history.push({
                pathname: "/Assessments",
            });
            window.location.reload();
        }
    }
    const titleRef = useRef();
    //fetching assessmentId from props as passed from assessment home page
    var assessmentId = props.location.state.assessmentId;
    var creditId = props.location.state.creditId;
    let currentTimerAssesss = null;
    //fetching questionObject from props as passed from assessment home page
    var questions = props.location.state.questionsList;

    //declaring other const for quiz related functionality
    const [toggleState, setToggleState] = useState(1);
    const [btnText, setbtnText] = useState("Next");
    const [totalQues, setTotalQues] = useState(0);
    const [currentQuestionIndex, setcurrentQuestionIndex] = useState(0);
    const [questionCounter, setQuestionCounter] = useState(0);
    const [currentTitleIndex, setcurrentTitleIndex] = useState(0);
    const [assessmentData, setAssessmentData] = useState(null);
    const [datetimeFromServerForProgress, setDateTimeFromServerForProgress] =
        useState(null);
    const [errorTitle, setErrorTitle] = useState("Time Reached!");
    const [errorTxt, setErrorTxt] = useState("You've reached your time limit for this assessment. You will be redirected to the assessment panel shortly.");
    const TitleList = questions;
       
    const currentQuestion =
        questions.TitleList[currentTitleIndex == null ? 0 : currentTitleIndex]
            .QuestionList[currentQuestionIndex == null ? 0 : currentQuestionIndex];
    const [checkedRadio, setCheckedRadio] = useState(null);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    const [show, setShow] = useState(false);
    const [AssessmentName, setAssessmentName] = useState(
        questions.AssessmentName
    );
    const [Title, setTitle] = useState(
        questions.TitleList[currentTitleIndex].TitleName
    );

    const [seconds, setSeconds] = useState(null);
    const [minus, setMinus] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //declaring local variables for side bar qestion highlighting
    // var count = 0;
    var did = 0;
    var countDots = 0;
    var topicNavCount = 0;
    let interval = useRef();
    let localTime = 0;

    //component DidMount
    //This is for persistence of data on refresh
    useEffect(() => {
        sessionStorage.removeItem('hourAssessment');
        // clear all interval
        let endTimer = setTimeout(() => {}, 100000);
        for (let i = 0; i <= endTimer; i++) {
            // 清除setInterval创建的定时器
            clearInterval(i)
            // 清除setTimeout创建的定时器
            clearTimeout(i)
        }
        //set the values after taking from the session/local storage
        const currentSessionValues = () => {
            var sessionDetails = assessmentService
                .GetCurrentAssessmentSessionValues(userId, assessmentId, creditId);
            var detailsFetched = sessionDetails.then((response) => {
                if (response !== null && response.AssessmentId != 0) {
                    var timeForStorage = sessionStorage.getItem("hourAssessment")!=='nontimer' ? response.Create_Date :'nontimer';
                    if (timeForStorage != 0 && timeForStorage > 0) {
                        setSeconds(formatToHoursAndMinutes(timeForStorage));
                        localTime = timeForStorage;
                    }else if(timeForStorage =='nontimer'){
                        setSeconds(formatToHoursAndMinutes(0));
                        localTime = 0;
                    }else {
                        setSeconds(formatToHoursAndMinutes(response.Create_Date));
                        localTime = response.Create_Date;
                        sessionStorage.setItem("hourAssessment", JSON.stringify(localTime));
                    }
                    setQuestionCounter(response.QuestionCounter);
                    setTitle(response.Title);
                    setAssessmentName(response.AssessmentName);
                    let topicIndex = 0;
                    let questionIndex = 0;
                    if(response.TopicId !== '' && response.QuestionCounter !== ''){
                         topicIndex = questions.TitleList.findIndex(x=>
                            x.TopicId == response.TopicId
                        )
                         questionIndex = questions.TitleList[topicIndex].QuestionList.findIndex(x=>x.QuestionId == response.QuestionId)
                        if(questionIndex == questions.TitleList[topicIndex].QuestionList.length - 1) {
                            if(topicIndex !==questions.TitleList.length -1){
                                questionIndex = 0;
                                topicIndex = topicIndex +1;
                            }else{
                                setQuestionCounter(response.QuestionCounter-1);
                                setCheckedRadio(response.OptionsId);
                            }
                        }else{
                            questionIndex = questionIndex +1;
                        }
                    }else{
                         topicIndex = 0;
                         topicIndex = 0
                    }
                    setcurrentTitleIndex(topicIndex);
                    setcurrentQuestionIndex(questionIndex);
                    // setCheckedRadio(response.OptionsId);
                    // assessmentService.GetNoOfQuestionsPerAssessment(assessmentId).then(response1 => {
                    //     if (
                    //         response.QuestionCounter == response1
                    //     ) {
                    //         setbtnText("Submit");
                    //     }
                    // })
                    clearInterval(interval.current);
                    interval.current = setInterval(startTimer, 1000);
                }
            });
        }
        const startTimer = () => {
            if (window.location.href.indexOf("AssessmentQuiz") > -1 &&sessionStorage.getItem("hourAssessment")!=='nontimer') {
                localTime = localTime - 1;
                if(localTime !== 0){
                    setSeconds(formatToHoursAndMinutes(localTime));
                    sessionStorage.setItem("hourAssessment", JSON.stringify(localTime));
                    if (localTime % 5 == 0 &&
                        window.location.href.indexOf("AssessmentQuiz") > -1) {
                        assessmentService
                            .SetPendingTimeForTimer(userId, assessmentId, creditId)
                            .then((response) => {
                                var timeForStorage = Number(
                                    JSON.parse(sessionStorage.getItem("hourAssessment"))
                                );
                                setSeconds(formatToHoursAndMinutes(timeForStorage));
                                localTime = timeForStorage;
                                sessionStorage.setItem(
                                    "hourAssessment",
                                    JSON.stringify(localTime)
                                );
                            });
                        }
                }else{
                    setSeconds(formatToHoursAndMinutes(localTime));
                    sessionStorage.setItem("hourAssessment", JSON.stringify(localTime));
                    if (localTime % 5 == 0 &&
                        window.location.href.indexOf("AssessmentQuiz") > -1) {
                        assessmentService
                            .SetPendingTimeForTimer(userId, assessmentId, creditId)
                            .then((response) => {
                                setSeconds(formatToHoursAndMinutes(0));
                                sessionStorage.removeItem("hourAssessment");
                                sessionStorage.removeItem("DateTimeServer");
                                localTime = 0;
                                // clearInterval(interval.current);
                                setShow(true);
                                setTimeout(function () {
                                    setSeconds(formatToHoursAndMinutes(0));
                                    sessionStorage.removeItem("hourAssessment");
                                    sessionStorage.removeItem("DateTimeServer");
                                    localTime = 0;
                                    setShow(false);
                                    props.history.push({
                                        pathname: "/Assessments",
                                    });
                                    window.location.reload();
                                }, 2000);
                            });
                        }
                }
            }
        };
        document.getElementById("assessment-wrapper-list").style.height = getComputedStyle(document.getElementById("assessment-wrapper-home"),null).height
        currentSessionValues();
        return () => {
            // clear all interval
            let endTimer = setTimeout(() => {}, 100000);
            for (let i = 0; i <= endTimer; i++) {
                // 清除setInterval创建的定时器
                clearInterval(i)
                // 清除setTimeout创建的定时器
                clearTimeout(i)
            }
        }
    }, []);

    const SetTimeForTimer = () => {
        if (seconds == null) {
            assessmentService
                .GetPendingTimeForTimer(userId, assessmentId, creditId)
                .then((response) => {
                    if (response !== null) {
                        if (response === 0) {
                            //clearInterval(interval.current);
                            setShow(true);
                            setTimeout(function () {
                                setShow(false);
                                sessionStorage.removeItem("hourAssessment");
                                sessionStorage.removeItem("DateTimeServer");
                                props.history.push({
                                    pathname: "/Assessments",
                                });
                                window.location.reload();
                            }, 2000);
                        } else if(response === 'notimer'){
                            setSeconds(formatToHoursAndMinutes(0));
                            sessionStorage.setItem("hourAssessment",'nontimer');

                        } else if(response === ''){
                            setErrorTxt('Please try again.');
                            setErrorTitle("Reminder");
                            setShow(true);
                            setTimeout(function () {
                                setSeconds(formatToHoursAndMinutes(0));
                                sessionStorage.removeItem("hourAssessment");
                                sessionStorage.removeItem("DateTimeServer");
                                localTime = 0;
                                setShow(false);
                                props.history.push({
                                    pathname: "/Assessments",
                                });
                                window.location.reload();
                            }, 2000);
                        }
                        else{
                            var timeForStorage = Number(
                                JSON.parse(sessionStorage.getItem("hourAssessment"))
                            );
                            if (timeForStorage != 0 && timeForStorage > 0) {
                                setSeconds(formatToHoursAndMinutes(timeForStorage));
                                localTime = timeForStorage;
                            } else {
                                setSeconds(formatToHoursAndMinutes(response));
                                localTime = response;
                                sessionStorage.setItem(
                                    "hourAssessment",
                                    JSON.stringify(localTime)
                                );
                            }
                        }
                    }
                });
        }
    };

    useEffect(() => {
        if (seconds == null) {
            SetTimeForTimer();
        }

        console.log(questions.AssessmentName);
        setAssessmentName(questions.AssessmentName);
        console.log(AssessmentName);

        setTitle(questions.TitleList[currentTitleIndex].TitleName);

        //Fetches the current date and time from the api
        //async function fetchCurrentDateAndTimeFromAPI() {
        const currentTime = () => {
            var timeData = assessmentService.GetCurrentDateAndTime();
            var dateAndTimeDetails = timeData.then((response) => {
                if (response !== null) {
                    currentTimerAssesss = response;
                    window.sessionStorage.setItem("DateTimeServer", currentTimerAssesss);
                    //  startTimer();
                    //starting the timer on the load of the page
                }
            });
        }
        //}
        /*if (datetimeFromServerForProgress == null) {*/
        const serverTime = () => {
            var serverTimeDetails = assessmentService.GetCurrentDateAndTimeFromServer();
            var detailsTime = serverTimeDetails.then((response) => {
                if (response !== null) {
                    setDateTimeFromServerForProgress(response);
                }
            });
        }
        //Fetches assessment data
        //if (assessmentData === null) {
        const allAssessmentDetails = () => {
            var assessDetails = assessmentService.GetAllAssessmentDetails();

            var assessmentDetails = assessDetails.then((response) => {
                if (response !== null) {
                    setAssessmentData(response);
                }
            });
        }
        //Gets total number of questions from the api
        //if (totalQues == 0) {
        const noOfQuestions = () => {
            var noOfQues = assessmentService
                .GetNoOfQuestionsPerAssessment(assessmentId);
            var quesDet = noOfQues.then((response) => {
                if (response !== null) {
                    setTotalQues(response);
                    // setTotalQues(10);
                }
            });
        }
        currentTime();
        serverTime();
        allAssessmentDetails();
        noOfQuestions();
    }, [AssessmentName]);
    useEffect(()=>{
        document.getElementById("assessment-wrapper-list").style.height = getComputedStyle(document.getElementById("assessment-wrapper-home"),null).height
    },[questionCounter])

    // TODO: remove the following & use moment.js to handle time
    const formatToHoursAndMinutes = (secondsVal) => {
        if (secondsVal < 0) {
            setMinus("-")
            secondsVal = Math.abs(secondsVal);
            var sec_num = parseInt(secondsVal, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;

            return [hours, minutes, seconds]
                .map((v,i) => (v < 10 ? "0" + v : v))
                .filter((v, i) =>  v !== "00" || i > 0)
                .join(":");

        }else{
            var sec_num = parseInt(secondsVal, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;

            return [hours, minutes, seconds]
                .map((v) => (v < 10 ? "0" + v : v))
                .filter((v, i) => v !== "00" || i > 0)
                .join(":");
        }
        
    };

    // Button handler for moving forward with questions
    const handleNextButtonClick = () => {
        //getting the current user details from the local storage in order to pass to api the details and id of the current user giving the assessment
        // 控制问题列表自动移动
        document.getElementById("scrollbar1").scrollTop = document.getElementById('answering').offsetTop;
        var totalNum = questions.TitleList[currentTitleIndex].NoOfQuestionsPerTopic;
        if (
            currentQuestionIndex == totalNum - 1 &&
            questionCounter !== totalQues - 1
        ) {
            //adding the user assessment progress on every click, saving the response for the current answer.
            assessmentService.GetCurrentDateAndTimeFromServer().then((response) => {
                if (response !== null) {
                    setDateTimeFromServerForProgress(response);

                    assessmentService
                        .AddUserAssessmentProgress(
                            userId,
                            assessmentId,
                            questions.TitleList[currentTitleIndex].TopicId,
                            currentQuestion.QuestionId,
                            checkedRadio,
                            datetimeFromServerForProgress,
                            datetimeFromServerForProgress,
                            creditId
                        )
                        .then((response) => {
                            if (response !== null) {
                                setbtnText("Next");
                                setcurrentQuestionIndex(0);

                                //incrementing title after the questions for one section has passed
                                setCheckedRadio(0);
                                setcurrentTitleIndex(currentTitleIndex + 1);
                                setTitle(questions.TitleList[currentTitleIndex + 1].TitleName);
                                setQuestionCounter(questionCounter + 1);
                                countDots = 0;
                                checkIsAnswer(
                                    questions.TitleList[currentTitleIndex + 1].TopicId,
                                    questions.TitleList[currentTitleIndex + 1].QuestionList[0].QuestionId);
                            }
                        });
                }
            });
        } else if (questionCounter == totalQues - 1) {
            // setbtnText("Submit");

            //submitting the quiz will send the user on review page where he/she can review and change answers.
            //Get the question and answers for review
            assessmentService.GetCurrentDateAndTimeFromServer().then((response) => {
                if (response !== null) {
                    setDateTimeFromServerForProgress(response);
                    assessmentService
                        .AddUserAssessmentProgress(
                            userId,
                            assessmentId,
                            questions.TitleList[currentTitleIndex].TopicId,
                            currentQuestion.QuestionId,
                            checkedRadio,
                            datetimeFromServerForProgress,
                            datetimeFromServerForProgress,
                            creditId
                        )
                        .then((response) => {
                            return response;
                        })
                        .then((data) => {
                            assessmentService
                                .GetReviewAnsweredQuestions(userId, assessmentId, creditId)
                                .then((response) => {
                                    if (response !== null) {
                                        setCheckedRadio(0);
                                        let newData = response[0].TitleList.map((sitem,index)=>{
                                            sitem.index = index;
                                            sitem.QuestionList.map((qitem,i)=>{
                                                qitem.index = i;
                                                return qitem;
                                            })
                                            return sitem;
                                        })
                                        response[0].TitleList = newData;
                                        sessionStorage.removeItem("ReSubmitQuestionKey");
                                        sessionStorage.removeItem("AssessmentQuizKey");
                                        sessionStorage.setItem("ReviewAssessmentKey", "false");
                                            props.history.push({
                                            pathname: "/ReviewAssessment",
                                            state: {
                                                questionsList: response[0],
                                                assessmentId: assessmentId,
                                                creditId: creditId,
                                            },
                                        });
                                    }
                                });
                        });
                }
            });
        } else {
            //adding the user assessment progress on every click, saving the response for the current answer.
            assessmentService.GetCurrentDateAndTimeFromServer().then((response) => {
                if (response !== null) {
                    setDateTimeFromServerForProgress(response);

                    assessmentService
                        .AddUserAssessmentProgress(
                            userId,
                            assessmentId,
                            questions.TitleList[currentTitleIndex].TopicId,
                            currentQuestion.QuestionId,
                            checkedRadio,
                            datetimeFromServerForProgress,
                            datetimeFromServerForProgress,
                            creditId
                        )
                        .then((response) => {
                            if (response !== null) {
                                // if (questionCounter == totalQues - 2) {
                                //     setbtnText("Submit");
                                // } else {
                                //     setbtnText("Next");
                                // }
                                setcurrentQuestionIndex(currentQuestionIndex + 1);
                                //incrementing the question counter
                                setQuestionCounter(questionCounter + 1);
                                setCheckedRadio(0);

                                // setting the title
                                setTitle(questions.TitleList[currentTitleIndex].TitleName);
                                checkIsAnswer(questions.TitleList[currentTitleIndex].TopicId,questions.TitleList[currentTitleIndex].QuestionList[currentQuestionIndex + 1].QuestionId);
                            }
                        });
                }
            });
        }
    };
    const checkIsAnswer = (topicId,questionId) =>{
        assessmentService.GetSingleQuestionAnswer(
            userId,
            assessmentId,
            creditId,
            topicId,
            questionId
        ).then(response => {
            if(response.Status == 'success') {
                setCheckedRadio(response.Message)
            }
        })
    }
    // Button handler for moving backwards with questions
    const handlePreviousButtonClick = () => {
        setbtnText("Next");
        document.getElementById("scrollbar1").scrollTop = document.getElementById('answering').offsetTop;
        //checking if the currentQuestionIndex and currentTitleIndex is not zero as it should not allow the user to go decrement
        let topicId = '';
        let questionId =  '';
        if (!(currentQuestionIndex == 0 && currentTitleIndex == 0)) {
            if (currentQuestionIndex == 0) {
                setcurrentQuestionIndex(questions.TitleList[currentTitleIndex - 1].QuestionList.length - 1);
                setcurrentTitleIndex(currentTitleIndex - 1);
                setTitle(questions.TitleList[currentTitleIndex - 1].TitleName);
                setQuestionCounter(questionCounter - 1);
                countDots = 0;
                topicId = questions.TitleList[currentTitleIndex - 1].TopicId;
                questionId = questions.TitleList[currentTitleIndex - 1].QuestionList[questions.TitleList[currentTitleIndex - 1].QuestionList.length - 1].QuestionId
            } else {
                setcurrentQuestionIndex(currentQuestionIndex - 1);
                setQuestionCounter(questionCounter - 1);
                topicId = questions.TitleList[currentTitleIndex].TopicId;
                questionId = questions.TitleList[currentTitleIndex].QuestionList[currentQuestionIndex - 1].QuestionId;
            }
        }
        assessmentService.GetSingleQuestionAnswer(
            userId,
            assessmentId,
            creditId,
            topicId,
            questionId
        ).then(response => {
            if(response.Status == 'success') {
                setCheckedRadio(response.Message)
            }
        })
    };

    // Button handler for submitting each question answer
    const handleButtonClick = (e) => {
        switch (e.target.id) {
            case "previous-button":
                document.documentElement.scrollTop = 0;
                handlePreviousButtonClick();
                break;
            case "submit-button":
                if (checkedRadio == "0" || checkedRadio == undefined) {
                    //if the radio for the answer is not selected, giving an alert /promt to answer the specific question
                    alert("Please select one option!");
                } else {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    handleNextButtonClick();
                    //since the counter starts from zero checking the second last question next hit making the text of button to submit
                    // if (questionCounter == totalQues - 2) {
                    //     setbtnText("Submit");
                    // }
                }
                break;

            default:
                break;
        }
    };

    const onChangeOfCheckedOption = (e) => {
        setCheckedRadio(e.target.id);
    };

    return (
        <>
            <Row>
                <Modal backdrop="static"
                    size="sm"
                    show={show}
                    onHide={handleClose}
                    className="modal-colored modal-danger"
                >
                    <Modal.Header style={{justifyContent: 'space-around'}}>
                        <Modal.Title>{errorTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center modal-body">
                            <p className="mb-0 text-white">
                                {" "}
                                {errorTxt}
                            </p>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className="col-lg-8 col-md-8">
                    <Card className="my-card assessment-wrapper-home" id="assessment-wrapper-home">
                        <CardBody className="pb-0">
                            <Row className="align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="float-left">
                                        <h1 className="" style={{fontSize:'1.75rem'}}>
                                            {AssessmentName ? AssessmentName : "Assessment Name"}
                                        </h1>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="float-right">
                                        <h2 className="h3 text-primary font-weight-bold">
                                            {Title ? Title : "Topic"}
                                        </h2>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                        <hr className="my-hr" />

                        <CardBody>
                            <Row>
                                <div className="col-lg-1 col-md-1">
                                    <div className="question-number rounded-circle text-white font-weight-bold h3">
                                        {" "}
                                        {questionCounter + 1}
                                    </div>
                                </div>

                                <div className="col-lg-11 col-md-11">
                                    <p className="textDisable"> {currentQuestion.Questions}</p>
                                </div>
                            </Row>
                        </CardBody>
                        <hr className="my-hr" />

                        <CardBody className="py-0">
                            <Row>
                                <div className="col-lg-12 col-md-12">
                                    <h4>Answer</h4>
                                </div>
                            </Row>
                        </CardBody>
                        <CardBody className="py-0">
                            {currentQuestion.OptionsWithScore.map((x,i) => (
                                <div className="option-padd" key={i}>
                                    <div className="quiz-options-div">
                                        <div className="form-check" key={x.OptionsId}>
                                            <span className="radio-container">
                                                <input
                                                    name="radios-example"
                                                    type="radio"
                                                    className="form-check-input"
                                                    id={x.OptionsId}
                                                    value={x.Score}
                                                    checked={checkedRadio == x.OptionsId}
                                                    onChange={onChangeOfCheckedOption}
                                                />
                                                <span className="checkmark"></span>
                                            </span>

                                            <label
                                                title=""
                                                className="form-check-label"
                                                htmlFor={x.OptionsId}
                                                dangerouslySetInnerHTML={{__html:x.Options}}
                                            >
                                               
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </CardBody>

                        <CardBody>
                            <Row className="align-items-top">
                                <div className="col-lg-4 col-md-4">
                                    {" "}
                                    {questions.TitleList[currentTitleIndex].QuestionList.map((x,i) => (
                                        <FontAwesomeIcon
                                            className={
                                                countDots <= currentQuestionIndex
                                                    ? "text-primary"
                                                    : "text-secondary"
                                            }
                                            key={i}
                                            icon={faCircle}
                                            id={(countDots += 1)}
                                            fixedWidth
                                        />
                                    ))}
                                </div>
                                <div className="col-lg-8 col-md-8" style={{display: "flex",justifyContent: "flex-end"}}>
                                   {!(currentQuestionIndex == 0 && currentTitleIndex == 0) &&
                                        <>
                                        <button className="quiz-submit-btn btn btn-pill btn-primary d-block mr-0  col-4" id="previous-button" onClick={handleButtonClick}>Back</button>
                                        </>
                                    }
                                    <button
                                        id="submit-button"
                                        className="quiz-submit-btn btn btn-pill btn-primary d-block mr-0 ml-3 col-4"
                                        onClick={handleButtonClick}
                                    >
                                        {btnText}
                                    </button>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-4 col-md-4">
                    <Card
                        className="my-card"
                        id="assessment-wrapper-list"
                        // style={{'--maxHeight': `${getComputedStyle(document.getElementById("assessment-wrapper-home"),null).height}`}}
                    >
                        {sessionStorage.getItem("hourAssessment")!=='nontimer'?
                            <>
                            <CardHeader className="pb-0">
                            <Row>
                                <div className="col-lg-12 col-md-12">
                                    <Row>
                                        <Col sm={4} md={4}>
                                            <img
                                                src={Watch}
                                                className="img-responsive mt-2 mx-auto d-block"
                                                width="80"
                                                height="80"
                                            />
                                        </Col>
                                        <Col sm={8} md={8}>
                                            <div>
                                                <p className="mb-0">Your time left</p>
                                                <h3 className="display-4 font-weight-bold text-dark" style={{fontSize:"2.9rem"}}>
                                                    {minus}{seconds}
                                                </h3>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Row>
                            </CardHeader>
                            <hr className="my-hr" />
                            </> : <div style={{marginTop: "2rem",width:'100%'}}></div>
                        }
                       
                        <div className="scrollbar1" id="scrollbar1" style={{overflow: 'scroll'}}>
                        {sessionStorage.getItem("hourAssessment") !=='nontimer' &&  
                        <>
                            <CardBody
                                className="py-0"
                                style={{
                                    minHeight: "auto",
                                }}
                            >
                                <Row>
                                    <div className="h1 font-weight-bold text-dark text-center px-2" style={{width:'100%',fontSize:'1.75rem'}}>
                                    THIS IS A <span style={{color:'red'}}>TIMED TASK!</span>
                                    </div>
                                    {/* <p style={{textAlign:'center',fontSize:'0.9rem'}}>
                                        You are given a total of {questions.DefaultTime} to complete the evaluation. 
                                        The evaluation consists of {questions.TopicNumber} sections. 
                                        To manage your time effectively, spending no more than 5 minutes on each section is suggested. 
                                        This approach will ensure you cover all sections within the first 40 minutes of your allocated time. 
                                        The remaining 20 minutes should be used for reviewing your responses and revisiting any answers you may have doubts about. 
                                        Please make sure you submit your completed assessment within the {questions.DefaultTime} time frame. Good luck with your evaluation!
                                    </p> */}
                                </Row>
                            </CardBody>
                            <hr className="my-hr" />
                        </>
                        }
                        <div className="py-0 text-center">
                            <div className="h1 font-weight-bold text-dark px-2">
                                Your Assessment Progress
                            </div>
                        </div>
                        <hr className="my-hr" />
                        <CardBody className="py-0 scrollbar1"  style={{overflowY: "scroll"}}>
                            <Row>
                                <Col md={12} sm={12}>
                                    {/*sidebar*/}
                                    <div className="my-sidenav">
                                        {/*  */}
                                        <div>
                                            {TitleList.TitleList.map((x,i) => (
                                                
                                                <div id={(topicNavCount += 1)} ref={titleRef} key={i}>
                                                    {Title == x.TitleName&&(
                                                        <div style={{display: "none"}}>{(did+=1)}</div>
                                                    )}
                                                    <div className={(Title == x.TitleName
                                                                    ? "question-list-title-ative border border-primary bg-primary text-white "
                                                                    : "") +
                                                                "right-sidebar-h5 pl-2"}
                                                        id={(Title == x.TitleName?"answering":'')}
                                                        style={{borderRadius:'0.3rem'}}
                                                    ><div style={{width:'83%',display:'inline-block'}}>{x.TitleName}</div><span className="pr-1 float-right">{Title == x.TitleName? Math.floor(((currentQuestionIndex)/countDots)*100)+'%':(did == 0 ? '100%':'')}</span></div>
                                                   { Title == x.TitleName && <Card className="my-question-card mb-2 pl-1 pt-2 shadow-none" style={{fontSize:'0.9rem'}}>
                                                           {x.TitleDescription}
                                                    </Card>}
                                                    <hr className="my-hr" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        </div>
                        
                    </Card>
                </div>
            </Row>
        </>
    );
};

const AssessmentQuiz = (props) => (
    <>
        <QuizView {...props} />
    </>
);

export default AssessmentQuiz;
