import axios from "axios";

const API_URL = process.env.REACT_APP_API_LOGIN+'Login/';
// const API_URL = "http://localhost:57294/api/Login/";

/*2022-9-30-2022-10-7*/
const GetPartnerLogoById = (mainId) => {
  return axios
    .post(API_URL + "GetPartnerLogoById", {
      mainId
    })
    .then((response) => {
      if(response.data.code==200){
        if(response.data.partnerLogoUrl!=""&&response.data.partnerLogoUrl!=null){
          return response.data.partnerLogoUrl;
          // return require('../assets/img/companyLogoImg/'+response.data.partnerLogoUrl).default;
        }else{
          return '';
        }
      }else{ 
        alert(response.data.errMsg)
      }
    });
}
const GetPartnerLogoInverseById = (mainId) => {
  return axios
    .post(API_URL + "GetPartnerLogoInverseById", {
      mainId
    })
    .then((response) => {
      if(response.data.code==200){
        if(response.data.partnerLogoUrl!=""&&response.data.partnerLogoUrl!=null){
          return response.data.partnerLogoUrl;
          // return require('../assets/img/companyLogoImg/'+response.data.partnerLogoUrl).default;
        }else{
          return '';
        }
      }else{ 
        alert(response.data.errMsg)
      }
    });
}
const GetCompanyLogoById = (mainId) => {
  return axios
    .post(API_URL + "GetCompanyLogoById", {
      mainId
    })
    .then((response) => {
      if(response.data.code==200){
        sessionStorage.setItem("companyLogoInfo",JSON.stringify(response.data) )
        if(response.data.companyLogoUrl!=""&&response.data.companyLogoUrl!=null){  
          return response.data.companyLogoUrl;
          // return require('../assets/img/companyLogoImg/'+response.data.companyLogoUrl).default;
        }else{
          return '';
        }
      }else{
        alert(response.data.errMsg);
        return ''
      }
    });
}
const GetLoginLogoByCompany = (companyName) => {
  return axios
    .post(API_URL + "GetLoginLogoByCompany", {
      companyName
    })
    .then((response) => {
      if(response.data.code==='200'){
        return response;
      }else{
        alert(response.data.errMsg);
        return ''
      }
    });
}
const getLadingCompanyLogo = () => {
  return JSON.parse(sessionStorage.getItem("LadingCompanyLogo"));
};

/*end*/
const registerUser = (FirstName, LastName, Email, Password, UserJobRole) => {
  let companyLogo = getCurrentCompanyLogo();
  let companyId =
    companyLogo !== null && companyLogo.logoDetails.CompanyId
      ? companyLogo.logoDetails.CompanyId
      : 0;
  let PartnerId = sessionStorage.getItem("PartnerId") ? JSON.parse(sessionStorage.getItem("PartnerId")) : 0
  return axios.post(API_URL + "RegisterUser?" + "companyId=" + companyId, {
    FirstName,
    PartnerId,
    LastName,
    Email,
    Password,
    UserJobRole,
  }).then((response) => {
    return response;
  });
};

const loginUser = (Email, Password) => {
  return axios
    .post(API_URL + "UserLogin", {
      Email,
      Password,
    })
    .then((response) => {
      if (response.data.AccessToken) {
        sessionStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};
const UserSessionLogin = (session) => {
  return axios
    .post(API_URL + "UserSessionLogin", {
      UserName:session
    })
    .then((response) => {
      if (response.data.AccessToken) {
        sessionStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logoutUser = () => {
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("companyList");
  sessionStorage.clear();
};

const getCurrentUser = () => {
  // if (JSON.parse(sessionStorage.getItem("user")) == null || JSON.parse(sessionStorage.getItem("user")) == undefined) {
  //   if(window.location.href !== `${document.location.protocol}//${window.location.host}/`){
  //         window.location.href = `${document.location.protocol}//${window.location.host}/`
  //   }
  // }else{
    return JSON.parse(sessionStorage.getItem("user"));
  // }

};

const getcompanLogoIfo = () => {
  return JSON.parse(sessionStorage.getItem("companLogoIfo"));
};

const registerCompany = (
  company_name,
  company_message,
  company_email,
  company_phone,
  company_contact
) => {
  var companyDetails = new FormData();
  companyDetails.append("company_name", company_name);
  companyDetails.append("company_message", company_message);
  companyDetails.append("company_email", company_email);
  companyDetails.append("company_phone", company_phone);
  companyDetails.append("company_contact", company_contact);
  //  companyDetails.append("company_email", company_email)
  //companyDetails.append("file", file.file)

  return axios.post(API_URL + "RegisterCompany", companyDetails);
};

const getCompanyNames = (userId) => {
  return axios
    .get(API_URL + "GetCompanyList?" + "userId=" + userId)
    .then((response) => {
      if (response.data) {
        sessionStorage.setItem("companyList", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const resetPasswordIfEmailValid = (emailId) => {
  return axios
    .get(API_URL + "ResetPasswordIfEmailValid?" + "emailId=" + emailId)
    .then((response) => {
      //console.log(response.data.Message);
      return response.data;
    });
};

const checkResetCode = (emailId,resetCode,needChangeStatus) => {
  return axios
    .post(API_URL + "CheckResetCode",{emailId: emailId,resetCode:resetCode,needChangeStatus:needChangeStatus})
    .then((response) => {
      return response.data;
    });
};

const changePasswordIfEmailValid = (viewId, newPassword,resetCode) => {
  return axios
    .get(API_URL + "ChangePassword?", {
      params: { viewId: viewId, newPassword: newPassword,resetCode:resetCode},
    })
    .then((response) => {
      //console.log(response.data.Message);
      return response.data;
    });
};
const getCompanyDetailsByCompanyId = (companyId) => {
  return axios
    .get(API_URL + "GetCompanyDetailsByCompanyId?" + "companyId=" + companyId)
    .then((response) => {
      if (response) {
        response.data.CompanyName = "";
        sessionStorage.removeItem("companyDetails");
        sessionStorage.setItem("companyDetails", JSON.stringify(response.data));
      }

      return response.data;
    });
};
const getCompanyList = () => {
  return JSON.parse(sessionStorage.getItem("companyList"));
};

const loginCompany = (Email, Password) => {
  return axios
    .post(API_URL + "CompanyLogin", {
      Email,
      Password,
    })
    .then((response) => {
      if (response.data.AccessToken) {
        sessionStorage.removeItem("company");
        sessionStorage.setItem("company", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const ChangePasswordFirstLogin = (Email, newPassword) => {
  return axios
    .get(API_URL + "ChangePasswordFirstLogin?", {
      params: { Email: Email, newPassword: newPassword},
    })
    .then((response) => {
      return response.data;
    });
};



const GetLoginLogoByCompanydefault = (companyname,type) => {
  return axios
      .post(API_URL + "GetLoginLogoByCompany", {  companyName: companyname,type:type })
      .then((response) => {
          //console.log(response.data.Message);
          sessionStorage.setItem("companLogoIfo", JSON.stringify(response.data));
          sessionStorage.setItem("PartnerId", JSON.stringify(response.data.partnerId));
          return response.data;
      });
};


const CheckActiveAccount = (authId) => {
  return axios
      .post(API_URL + "ActiveAccount", { authId: authId })
      .then((response) => {
          return response.data;
      });
};

const GetEmailTemplate = (UserID) => {
  return axios
      .post(API_URL + "GetEmailTemplate", { UserID: UserID })
      .then((response) => {
          return response.data;
      });
};

const SaveEmailTemplate = (UserID,TemplateId,TemplateDefaultsName,EmailSubject,EmailHeading,EmailSubHeading,EmailBody) => {
  return axios
      .post(API_URL + "SaveEmailTemplate", { UserID: UserID ,TemplateId:TemplateId,TemplateDefaultsName:TemplateDefaultsName,EmailSubject:EmailSubject,EmailHeading:EmailHeading,EmailSubHeading:EmailSubHeading,EmailBody:EmailBody})
      .then((response) => {
          return response.data;
      });
};


const logoutCompany = () => {
  sessionStorage.removeItem("company");
  sessionStorage.removeItem("companyList");
};

const getCurrentCompany = () => {
  return JSON.parse(sessionStorage.getItem("company"));
};

const getCurrentCompanyLogo = () => {
  return JSON.parse(sessionStorage.getItem("companyDetails"));
};

const SendHelpCenterEmail = (FirstName,LastName,Email,NatureOfEnquiry,Comments) => {

  var SendHelpCenterEmailData = new FormData();
  SendHelpCenterEmailData.append("FirstName", FirstName);
  SendHelpCenterEmailData.append("LastName", LastName);
  SendHelpCenterEmailData.append("Email", Email);
  SendHelpCenterEmailData.append("NatureOfEnquiry", NatureOfEnquiry);
  SendHelpCenterEmailData.append("Comments", Comments);

  return axios.post(API_URL + "SendHelpCenterEmail", SendHelpCenterEmailData);
};


export default {
  GetPartnerLogoById,
  GetCompanyLogoById,
  GetLoginLogoByCompanydefault,
  registerUser,
  loginUser,
  logoutUser,
  getCurrentUser,
  registerCompany,
  loginCompany,
  logoutCompany,
  getCurrentCompany,
  getCompanyNames,
  getCompanyDetailsByCompanyId,
  getCurrentCompanyLogo,
  getCompanyList,
  resetPasswordIfEmailValid,
  changePasswordIfEmailValid,
  checkResetCode,
  GetLoginLogoByCompany,
  getcompanLogoIfo,
  getLadingCompanyLogo,
  CheckActiveAccount,
  GetEmailTemplate,
  SaveEmailTemplate,
  GetPartnerLogoInverseById,
  SendHelpCenterEmail,
  ChangePasswordFirstLogin,
  UserSessionLogin
};
