import React, { useEffect, useState, useRef } from "react";

// Components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Media,
    Col,
    Container,
    Row,
    UncontrolledCollapse,
    Input
} from "reactstrap";



// Assets
import {
    faBuilding,
    faUserCircle,
    faMale,
    faFlagCheckered,
    faQuestionCircle,
    faNewspaper,
    faShoppingBag,
    faCommentAlt,
    faPaperPlane,
    faChartBar,
    faBookOpen,
} from "@fortawesome/free-solid-svg-icons";
import {
    faUser,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from '@tinymce/tinymce-react';
import styles from '../style/setting/setting.module.css'
import arrow from "../../../assets/img/icons/right.png"
import AuthService from "../../../services/auth.service";
import { toast } from 'react-toastify';
toast.configure()
/*
* Settings View
*
* This is the main Settings View
*
* TODO: Other sub setting views needs to be implemented
*
* */

const Settings = () => {
    // setting菜单栏选项数据
    const optionsData = [
        {
            title: 'My Profile',
            icon: faUserCircle,
            id: 1
        },
        {
            title: 'My Company',
            icon: faBuilding,
            id: 2
        },
        {
            title: 'My Account',
            icon: faUser,
            id: 3
        },
        {
            title: 'hr'
        },
        {
            title: 'Assessments',
            icon: faFlagCheckered,
            id: 4
        },
        {
            title: 'Questions',
            icon: faQuestionCircle,
            id: 5
        },
        {
            title: 'Reports',
            icon: faNewspaper,
            id: 6
        },
        {
            title: 'Courses',
            icon: faBookOpen,
            id: 7
        },
        {
            title: 'hr'
        },
        {
            title: 'Job Roles',
            icon: faShoppingBag,
            id: 8
        },
        {
            title: 'Topics',
            icon: faCommentAlt,
            id: 9
        },
        {
            title: 'Capabilities',
            icon: faChartBar,
            id: 10
        },
        {
            title: 'hr'
        },
    ];

    var currentUser = AuthService.getCurrentUser();
    if (currentUser.roleName == "super-admin" || currentUser.roleName == "partner") {
        optionsData.push({
            title: 'Email Notifications',
            icon: faPaperPlane,
            id: 11

        })
    }

    // EmailTemplate数据修改方法
    const handleSubjectChange = (content) => {
        setSubjectValue(content.target.value);
    }
    const handleHeadingChange = (content) => {
        setHeadingValue(content.target.value);
    }
    const handleSubHeadingChange = (content) => {
        setSubHeadingValue(content.target.value);
    }
    const handleEditorChange = (content, editor) => {
        setEmailBodyContent(content);
    }
    //选中的setting选项
    const [checkedSetting, setCheckedSetting] = useState(0)
    // Email修改功能用到的控制数据
    const [showEmailoptions, setShowEmailoptions] = useState(false); //控制EmailTempate选项显示
    const [EmailoptionsData, setEmailoptionsData] = useState([]);
    //  选择EmailTempate时input中显示的内容
    const [Emailoptioncheckedtxt, setEmailoptioncheckedtxt] = useState('Select Email Template to edit');
    const [showEmailTemplate, setShowEmailTemplate] = useState(false);
    const [SubjectValue, setSubjectValue] = useState('welcome');
    const [HeadingValue, setHeadingValue] = useState('');
    const [SubHeadingValue, setSubHeadingValue] = useState('');
    const [EmailBodyContent, setEmailBodyContent] = useState('');
    const [EmailVariables, setEmailVariables] = useState('');
    const [TemplateId, setTemplateId] = useState('');
    const [TemplateDefaultsName, setTemplateDefaultsName] = useState('');
    const [TemplateBtntxt, setTemplateBtntxt] = useState('');
    const [TemplateImgurl, setTemplateImgurl] = useState('');
    const [TemplateShowVerificationCode, setTemplateShowVerificationCode] = useState(false);
    const [TemplateTitlecolor, setTemplateTitlecolor] = useState("#707070");

    // Email修改功能用到的方法
    const ChangearrowStyle = (index) => {
        if (document.getElementById('arrow' + index).classList.length == 0) {
            document.getElementById('arrow' + index).classList.add('questionListIconAtive');
        } else {
            if (document.getElementById('arrow' + index).classList[0] == 'questionListIconAtive') {
                document.getElementById('arrow' + index).classList.remove('questionListIconAtive');
                document.getElementById('arrow' + index).classList.add('questionListIconRestore');
            } else {
                document.getElementById('arrow' + index).classList.remove('questionListIconRestore');
                document.getElementById('arrow' + index).classList.add('questionListIconAtive');
            }
        }
    }
    // 选中模板，渲染模板数据
    const ChooseEmailTemplate = (template) => {
        setEmailoptioncheckedtxt(template.TemplateName);
        setShowEmailoptions(false);
        setShowEmailTemplate(true);
        setSubjectValue(template.EmailTemplateDefault.EmailSubject);
        setHeadingValue(template.EmailTemplateDefault.EmailHeading);
        setSubHeadingValue(template.EmailTemplateDefault.EmailSubHeading);
        setEmailBodyContent(template.EmailTemplateDefault.EmailBody);
        setEmailVariables(template.EmailTemplateDefault.EmailVariables);
        setTemplateId(template.TemplateId);
        setTemplateDefaultsName(template.EmailTemplateDefault.TemplateDefaultsName);
        switch (template.TemplateId) {
            case '1': setTemplateBtntxt('Activate Account'); setTemplateImgurl('https://onlinetest.etravel.net.cn/test/WELCOME.png');setTemplateTitlecolor('#707070'); setTemplateShowVerificationCode(false);break;
            case '2': setTemplateBtntxt('Reset Password'); setTemplateImgurl('https://onlinetest.etravel.net.cn/test/RESETPASSWORD.png');setTemplateTitlecolor('#707070'); setTemplateShowVerificationCode(true); break;
            case '3': setTemplateBtntxt('View Results'); setTemplateImgurl('https://onlinetest.etravel.net.cn/test/CONGRATULATIONS.png');setTemplateTitlecolor('#707070');setTemplateShowVerificationCode(false); break;
            case '4': setTemplateBtntxt('Start Assessing'); setTemplateImgurl('https://onlinetest.etravel.net.cn/test/LOGINREMINDER.png');setTemplateTitlecolor('#707070'); setTemplateShowVerificationCode(false);break;
            case '5': setTemplateBtntxt('View Results'); setTemplateImgurl('https://onlinetest.etravel.net.cn/test/CONGRATULATIONS.png');setTemplateTitlecolor('#707070'); setTemplateShowVerificationCode(false);break;
            case '6': setTemplateBtntxt(''); setTemplateImgurl('https://onlinetest.etravel.net.cn/test/TEAMRESULTS.png');setTemplateTitlecolor('red');setTemplateShowVerificationCode(false); break;
            case '7': setTemplateBtntxt(''); setTemplateImgurl('https://onlinetest.etravel.net.cn/test/APPROVAL.png');setTemplateTitlecolor('#707070');setTemplateShowVerificationCode(false); break;
            default: break;
        }
    }

    // 选择setting选项
    const chooseSetting = (setting) => {
        setCheckedSetting(setting)
        if (setting == 11) {
            AuthService.GetEmailTemplate(currentUser.id).then(response => {
                setEmailoptionsData(response.EmailTeplateCategorys)
            })
        }
    }
    // 保存template
    const SaveEmailTemplate = () => {
        AuthService.SaveEmailTemplate(currentUser.id, TemplateId, TemplateDefaultsName, SubjectValue, HeadingValue, SubHeadingValue, EmailBodyContent).then(response => {
            if (response.Status == 'success') {
                toast.success('save successfully!', { position: toast.POSITION.TOP_CENTER });
                setShowEmailTemplate(false)
                AuthService.GetEmailTemplate(currentUser.id).then(response => {
                    setEmailoptionsData(response.EmailTeplateCategorys);
                })
            } else {
                toast.error(response.Message, { position: toast.POSITION.TOP_CENTER });
            }
        })
    }

    // const Editor = useRef()
    return (

        <div id="setting_div">
            <Container fluid className="p-0">
                {/* <Row className="mb-2 mb-xl-4"> */}
                <Row>
                    <Col md="2" xl="2">
                        <h2>Settings</h2>
                    </Col>
                    <Col md="10" xl="10">
                        {/* EditEmailTemplate */}
                        {checkedSetting == 11 && <div className={styles.EmailNotifications_head}>
                            <Card style={{ width: '20rem', 'borderRadius': '0.3rem' }}>
                                <CardBody style={{ padding: '0.5rem 1rem', display: 'flex', alignItems: 'center' }} onClick={() => { setShowEmailoptions(!showEmailoptions) }}><div style={{ width: '82%', display: 'inline-block', height: '1.4rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer' }}>{Emailoptioncheckedtxt}</div><div className={styles.EmailNotifications_head_arrow} ></div> </CardBody>
                            </Card>
                            {showEmailTemplate && <div>
                                <button className={styles.EmailNotifications_button} onClick={() => { SaveEmailTemplate() }} >Save</button>
                                <button className={styles.EmailNotifications_button} onClick={() => { setShowEmailTemplate(false) }} style={{ width: '90px', backgroundColor: '#ffffff', border: '1px solid #D5D5D5', color: '#000000', fontWeight: 'bold' }}>Cancel</button>
                            </div>}


                            {showEmailoptions && <Card className={styles.EmailNotifications_head_options}>
                                <CardBody style={{ maxHeight: '43rem', minHeight: '20rem', overflow: 'scroll' }}>
                                    <div>
                                        {EmailoptionsData.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className={styles.EmailNotificationsTemplate_option}  id={'collapseExample' + index} onClick={() => { ChangearrowStyle(index) }}>
                                                        <Row>
                                                            <Col md="3" xl="3" className="pl-4">
                                                                <FontAwesomeIcon
                                                                    className="align-middle mr-2"
                                                                    icon={faUser}
                                                                    fixedWidth
                                                                    style={{ color: "#007BFF" }}
                                                                    size="xl"
                                                                />
                                                            </Col>
                                                            <Col md="9" xl="9" style={{ position: "relative" }}>
                                                                <span className="align-middle">{item.CategoryName}</span>
                                                                <img id={'arrow' + index} style={{ position: 'absolute', top: '0.9rem', right: '1rem', transform: 'rotate(90deg)', filter: 'grayscale(100%)' }} src={arrow} width="20" alt="" />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <UncontrolledCollapse toggler={'#collapseExample' + index} className={'collapseExample' + index}>
                                                        {item.EmailTemplates.map((child, i) => {
                                                            return (
                                                                <div className={styles.EmailNotificationsTemplateItem_option} onClick={(e) => { ChooseEmailTemplate(child) }} key={i}>
                                                                    <Row>
                                                                        <Col md="12" xl="12" style={{ textAlign: "center" }}>
                                                                            <span className="align-middle">{child.TemplateName}</span>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )
                                                        })}
                                                    </UncontrolledCollapse>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>

                                </CardBody>
                            </Card>}
                        </div>}
                    </Col>
                </Row>



                {/* </Row> */}
                <Row>
                    <Col sm="3" md="3" xl="3">
                        <Card>
                            <CardBody>
                                {optionsData.map((item, index) => {
                                    if (item.title == 'hr') {
                                        return (
                                            <div className="mb-2" key={index}>
                                                <hr className="my-0" />
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={styles.EmailNotifications_option} style={checkedSetting==item.id?{background:'#BCE0FD'}:{}} key={index} onClick={(e) => { chooseSetting(item.id) }}>
                                                <Row>
                                                    <Col md="2" xl="2" style={{ paddingLeft: '2.2rem' }}>
                                                        <FontAwesomeIcon
                                                            className="align-middle "
                                                            icon={item.icon}
                                                            fixedWidth
                                                            style={{ color: "#007BFF" }}
                                                            size="xl"
                                                        />
                                                    </Col>
                                                    <Col md="9" xl="9" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                        <span className="align-middle">{item.title}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }
                                })
                                }

                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="9" md="9" xl="9">
                        {showEmailTemplate && <Container fluid className="p-0">
                            <Row>
                                <Col md="5" xl="5" >
                                    <p>Email Subject</p>
                                    <input className={styles.EmailNotifications_input} value={SubjectValue} onChange={(e) => handleSubjectChange(e)} placeholder='Please edit Email Subject'></input>
                                    <p>Email Heading</p>
                                    <input className={styles.EmailNotifications_input} value={HeadingValue} onChange={(e) => handleHeadingChange(e)} placeholder='Please edit Email Heading'></input>
                                    <p>Email Sub Heading</p>
                                    <input className={styles.EmailNotifications_input} value={SubHeadingValue} onChange={(e) => handleSubHeadingChange(e)} placeholder='Please edit Sub Heading'></input>
                                    <p>Email Body</p>
                                    <Editor
                                        inline={false}
                                        selector='editorStateRef'
                                        apiKey='2w8fr7259busx29p01ihd82go254s4maagepq5bjpym9ytvm'
                                        // ref={Editor}
                                        value={EmailBodyContent}
                                        placeholder='Please edit Email Body'
                                        onEditorChange={handleEditorChange}
                                        // tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
                                        init={{
                                            height: '300',
                                            width: '100%',
                                            plugins: 'link',
                                            toolbar: `formatselect | link image |  bold italic strikethrough forecolor backcolor
                                        alignleft aligncenter alignright alignjustify
                                        numlist bullist outdent indent `,
                                            resize: false,
                                            skin: "bootstrap",
                                            menu: {
                                                file: { title: 'file', items: '' },
                                                edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
                                                view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
                                                // insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
                                                // format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat' },
                                                // tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
                                                // table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
                                                // favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | spellchecker | emoticons' }
                                            },
                                            // file_picker_types: 'file image media',
                                            // file_picker_callback: this.file_picker_callback,
                                            // automatic_uploads={false}
                                            images_upload_url: '',
                                            image_uploadtab: true,
                                        }}
                                    />
                                    <div className={styles.EmailNotifications_tip}>
                                        <p style={{ color: '#0C5460', fontSize: '1rem' }}>
                                            <p>Please find all the variable to load dynamic values</p>
                                            <span style={{ fontSize: '1rem' }}>{EmailVariables}</span>
                                        </p>

                                    </div>
                                </Col>
                                <Col md="7" xl="7" className={styles.emailscrollbar}>
                                    <div id="EmailStyle">
                                        <table width="601px" cellSpacing="0" cellPadding="0" style={{ borderCollapse: 'collapse', border: '1px solid #f3f3f3' }}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <table>
                                                            <tbody>
                                                                {currentUser.roleName == "partner" ? (
                                                                    <tr>
                                                                        <td style={{ width: '300px', textAlign: 'center', padding: '20px 0' }}>
                                                                            <img src="https://onlinetest.etravel.net.cn/test/skillsGAP.png" alt=""></img>
                                                                        </td>
                                                                        <td style={{ width: '300px', textAlign: 'center', padding: '20px 0' }}>
                                                                            <img src={sessionStorage.getItem('partner_logo')} alt=""></img>
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    <tr>
                                                                        <td style={{ width: '600px', textAlign: 'center', padding: '20px 0' }}>
                                                                            <img src="https://onlinetest.etravel.net.cn/test/skillsGAP.png" alt=""></img>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr style={{ height: '40px' }}>
                                                    <td style={{ background: '#F0F0F0', padding: '0' }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ background: '#F0F0F0', padding: '0' }}>
                                                        <p style={{ color: '#707070', fontSize: '21px', textAlign: 'center', margin: '0',fontWeight:'800' }}>{HeadingValue}</p>
                                                        {TemplateTitlecolor == '#707070'?(<p style={{  fontSize: '21px', textAlign: 'center', margin: '0', padding: '0 30px',color:'#707070' ,fontWeight:'800'}} dangerouslySetInnerHTML={{ __html: SubHeadingValue }}></p>):
                                                        ( <p style={{  fonSize: '21px', textAlign: 'center', margin: '0', padding: '0 30px',color:'red',fontWeight:'800'}} dangerouslySetInnerHTML={{ __html: SubHeadingValue }}></p>)}
                                                        <img src={TemplateImgurl} style={{ display: 'block' }} alt=""></img>
                                                    </td>
                                                </tr>
                                                <tr style={{ height: '20px' }}></tr>
                                                <tr style={{ width: '600px' }}>
                                                    <td>
                                                        <table border="0" width="600px" cellSpacing="0" cellPadding="0" height="40px">
                                                            <tbody>
                                                                <tr style={{ width: '600px' }}>
                                                                    <td style={{ width: '25px' }}></td>
                                                                    <td style={{ width: '550px', fontsize: '12px', color: '#000000', fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: EmailBodyContent }}>
                                                                    </td>
                                                                    <td style={{ width: '25px' }}></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                {!TemplateShowVerificationCode && <tr style={{ height: '40px' }}></tr>}
                                                {TemplateShowVerificationCode &&
                                                    <tr style={{ height: '20px' }}></tr>
                                                }
                                                {TemplateShowVerificationCode && <tr style={{ width: '600px' }}>
                                                    <td>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ width: '132px' }}></td>
                                                                    <td style={{ width: '345px', textAlign: 'center', fontSize: '24px', color: '#838383' }}>
                                                                        016P7C4LIS
                                                                    </td>
                                                                    <td style={{ width: '132px' }}></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>}

                                                {TemplateShowVerificationCode && <tr style={{ height: '30px' }}></tr>}
                                                {TemplateBtntxt != '' && <tr style={{ width: '600px' }}>
                                                    <td>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ width: '223px' }}></td>
                                                                    <td style={{ width: '163px', textAlign: 'center', backgroundColor: '#000000', height: '39px', borderRadius: '5px' }}>
                                                                        <span style={{ color: '#ffffff', textDecoration: 'none', fontSize: '14px' }}>{TemplateBtntxt}</span>
                                                                    </td>
                                                                    <td style={{ width: '223px' }}></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>}
                                                <tr style={{width: '600px'}}>
                                                    <td><table border="0" width="600px" cellspacing="0" cellpadding="0" height="40px">
                                                            <tr style={{width: '600px'}}>
                                                                <td style={{width:'25px'}}></td>
                                                                <td style={{width:'550px',fontSize:'12px',color:'#707070'}}>
                                                                    <p style={{marginTop:'1rem'}}><span style={{color:' #707070'}}>Need help? <a href="https://app.skillsgapanalysis.com/help.aspx"> Click for support here {">"}</a></span></p>
                                                                    <p><span style={{color:' #707070'}}>SkillsGAP Analysis is a specialist tool designed exclusively for procurement, contract management, and supply chain professionals. It provides an in-depth, personalised understanding of your skills and capabilities, setting it apart from any other tool on the market.</span></p>
                                                                </td>
                                                                <td style={{width:'25px'}}></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="https://onlinetest.etravel.net.cn/test/footInfo.png" ></img>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </Container>}
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Settings;
