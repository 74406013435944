import axios from "axios";

const API_URL = process.env.REACT_APP_ASSESSMENT_API_URL;

const GetCurrentDateAndTime = () => {
    return axios
        .get(API_URL + "GetCurrentDateAndTime")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetCurrentDateAndTimeFromServer = () => {
    return axios
        .get(API_URL + "GetCurrentDateAndTimeFromServer")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};



const GetAllAssessmentDetails = () => {
    return axios
        .get(API_URL + "GetAllAssessmentDetails")
        .then((response) => {
            //console.log(response.data.Message);
            sessionStorage.setItem("assessment", JSON.stringify(response.data));
            return response.data;
        });
};
const GetAssessments = () => {
    var value = JSON.parse(sessionStorage.getItem("assessment"));
    if (value == null) {
        axios
            .get(API_URL + "GetAllAssessmentDetails")
            .then((response) => {
                //console.log(response.data.Message);
                sessionStorage.setItem("assessment", JSON.stringify(response.data));
                value = JSON.parse(sessionStorage.getItem("assessment"));
            });
    }

    return value
};
const AddEntryInUserTestTable = (userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "AddEntryInUserTestTable", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
};


const AssignTheAssessmentToUser = (partnerId,userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "AssignTheAssessmentToUser", { params: { partnerId: partnerId, userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
};
const AddUserAssessmentProgress = (userId, assessmentId, topicId, questionId, answerId, startDate, endDate,creditId) => {

    return axios
        .get(API_URL + "AddUserAssessmentProgress", { params: { userId: userId, assessmentId: assessmentId, topicId: topicId, questionId: questionId, answerId: answerId, startTime: startDate, endTime: endDate, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetReviewAnsweredQuestions = (userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "GetReviewAnsweredQuestions", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetCurrentAssessmentSessionValues = (userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "GetCurrentAssessmentSessionValues", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const SetPendingTimeForTimer = (userId, assessmentId, creditId) => {
    return axios
        .get(API_URL + "SetPendingTimeForTimer", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetPendingTimeForTimer = (userId, assessmentId, creditId) => {
    return axios
        .get(API_URL + "GetTimeForTimer", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetAllAssessmentList = (userId) => {

    return axios
        .get(API_URL + "GetAllAssessmentList", {
            params: {
                userId: userId
            }
        })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const UpdateUserTestTable = (userId, assessmentId, creditId) => {
    return axios
        .get(API_URL + "UpdateUserTestTable", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}
const GetNoOfQuestionsPerAssessment = (id) => {

    return axios
        .get(API_URL + "GetNoOfQuestionsPerAssessment", { params: { assessmentId: id } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const CheckIfTheAssessmentIsLocked = (userId, assessmentId) => {

    return axios
        .get(API_URL + "CheckIfTheAssessmentIsLocked", { params: { userId: userId, assessmentId: assessmentId } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const IsTimerExhausted = (dateOriginal, dateToCompare) => {

    return axios
        .get(API_URL + "IsTimerExhausted", { params: { dateOriginal: dateOriginal, dateCurrent: dateToCompare } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetAssessmentResultReport = (assessmentId, userId,guid) => {

    return axios
        .get(API_URL + "GetAssessmentResultReport", { params: { assessmentId: assessmentId, userId: userId, guid:guid } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetAiAssessmentCategoryList = (company_id) => {
    return axios
        .post(API_URL + "GetAiAssessmentCategoryList",{companyId:company_id})
        .then((response) => {
            return response.data;
        });
};

const GetAiAssessmentList = (company_id,categoryId) => {
    return axios
        .post(API_URL + "GetAiAssessmentList",{companyId:company_id,categoryId:categoryId})
        .then((response) => {
            return response.data;
        });
};

const CreateAssessmentFile = (data) => {
    return axios
        .post(API_URL + "CreateAssessmentFile",{"queryList":data})
        .then((response) => {
            return response.data;
        });
};

const CreateSimpleAssessmentFile = (data) => {
    return axios
        .post(API_URL + "CreateSimpleAssessmentFile",{"queryList":data})
        .then((response) => {
            return response.data;
        });
};

const GetReportLink = (userId,assessmentId,creditId) => {
    return axios
        .post(API_URL + "GetReportLink",{"userId":userId,"assessmentId":assessmentId,"creditId":creditId})
        .then((response) => {
            return response.data;
        });
};

const GetAssessmentNameListByUserId = (userId) => {
    return axios
        .post(API_URL + "GetAssessmentNameListByUserId",{"userId":userId})
        .then((response) => {
            return response.data;
        });
};

const AssignAssessment = (userId,assignUserId,assessmentIdList) => {
    return axios
        .post(API_URL + "AssignAssessment",{"userId":userId,"assignUserId":assignUserId,"assessmentIdList":assessmentIdList})
        .then((response) => {
            return response.data;
        });
};

const UnblockAssessment = (userId,assessmentId,creditId) => {
    return axios
        .post(API_URL + "UnblockAssessment",{"userId":userId,"assessmentId":assessmentId,"creditId":creditId})
        .then((response) => {
            return response.data;
        });
};

const GetAssessmentCompletePercent = (userId,companyId,startDate,endDate) => {
    return axios
        .post(API_URL + "GetAssessmentCompletePercent",{"userId":userId,"companyId":companyId,"startDate":startDate,"endDate":endDate})
        .then((response) => {
            return response.data;
        });
};

const GetAssessmentCredits = (userId) => {
    return axios
        .post(API_URL + "GetAssessmentCredits",{"userId":userId})
        .then((response) => {
            return response.data;
        });
};

const GetSingleQuestionAnswer = (userId,assessmentId, creditId,topicId,questionId) => {
    return axios
        .post(API_URL + "GetSingleQuestionAnswer",{"userId":userId,"assessmentId":assessmentId,"creditId":creditId,"topicId":topicId,"questionId":questionId})
        .then((response) => {
            return response.data;
        });
};

const ReGenerateReport = (data) => {
    return axios
        .post(API_URL + "ReGenerateReport",{"queryList":data})
        .then((response) => {
            return response.data;
        });
};

export default {
    CreateAssessmentFile,
    GetAiAssessmentList,
    GetAiAssessmentCategoryList,
    GetCurrentDateAndTime, 
    GetAllAssessmentDetails, 
    GetAssessments, 
    GetAllAssessmentList, 
    GetNoOfQuestionsPerAssessment, 
    AddEntryInUserTestTable, 
    AddUserAssessmentProgress, 
    GetReviewAnsweredQuestions, 
    IsTimerExhausted, 
    GetCurrentDateAndTimeFromServer, 
    GetCurrentAssessmentSessionValues, 
    SetPendingTimeForTimer, 
    GetPendingTimeForTimer, 
    UpdateUserTestTable, 
    AssignTheAssessmentToUser,
     CheckIfTheAssessmentIsLocked,
     GetAssessmentResultReport,
     GetReportLink,
     GetAssessmentNameListByUserId,
     AssignAssessment,
     UnblockAssessment,
     GetAssessmentCompletePercent,
     GetAssessmentCredits,
     CreateSimpleAssessmentFile,
     GetSingleQuestionAnswer,
     ReGenerateReport
};