import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Box } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import routes from "../routes/index";
import avatar from "../assets/img/avatars/avatar.png";
import logo from "../assets/img/logo/skillsgap-invert.png";
import AuthService from "../services/auth.service.js";
import learningplanService from "../services/learningplans.service.js";
import logoSecondary from "../assets/img/logo/comprara-invert.png";
import {Globe} from "react-feather"
const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        {
          (name === "Help Center" &&(window.location.href.indexOf("AssessmentQuiz") > -1 || window.location.href.indexOf("ReviewAssessment") >-1 ||window.location.href.indexOf("ReSubmitQuestionOption") >-1))  ?
            (<div style={{zIndex:'100'}} onClick={()=>{ window.open(`${document.location.protocol}//${window.location.host}/helpCenter`)}} className="sidebar-link" activeclassname="active">
            {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
            {name}
            {badgeColor && badgeText ? (
              <Badge color={badgeColor} size={18} className="sidebar-badge">
                {badgeText}
              </Badge>
            ) : null}
            </div>):
            (<NavLink to={to} className="sidebar-link" activeclassname="active">
            {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
            {name}
            {badgeColor && badgeText ? (
              <Badge color={badgeColor} size={18} className="sidebar-badge">
                {badgeText}
              </Badge>
            ) : null}
          </NavLink>)
        }
        
      </li>
    );
  }
);
const SidebarLinkItem = withRouter(
  ({ name, location}) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };
    return (
      <li className={"sidebar-item "}>
        
            <a href="https://comprara.com.au/procurement-glossary/" target="_blank" style={{zIndex:'100'}}  className="sidebar-link" activeclassname="active">
            <Globe size={18} className="align-middle mr-3" />
            {name}
          </a>
        
      </li>
    );
  }
);


const Sidebar = ({ location, sidebar, layout }) => {
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  var currentUser = null;
  var currentCompany = null;
  const user = AuthService.getCurrentUser();
  const company = AuthService.getCurrentCompany();
  /*2022-9-30-2022-10-7*/
  const [PartnerLogo, setPartnerLogo] = useState("");
  const [LearningPlans, setLearningPlans] = useState(false);  
  useEffect(() => {
    AuthService.GetPartnerLogoInverseById(user.partner_id).then(
      (response) => {
        setPartnerLogo(response);
        localStorage.setItem("partner_logo",response)
      }
    );
    learningplanService.GetUserLearningPlansList(currentUser.id).then((response) => {
      return response;
    })
    .then((data) => {
      if(data.AssessmentLearningPlanList.length > 0) {
        setLearningPlans(true);
      }
    })
    .catch((e) => {
        console.log(e);
    });
  }, []);
  /*end*/
  var routes1 = null;
  if (user) {
    currentUser = user;
    if (currentUser.roleName === "user") {
      routes1 = routes.filter(
        (x) =>
          x.name === "Dashboard" ||
          x.name === "Pages" ||
          x.name === "Assessments" ||
          x.name === "Help Center"
      );
    } else if (
      currentUser.roleName === "team-leader"
    ) {
      routes1 = routes.filter(
        (x) =>
          x.name === "Dashboard" ||
          x.name === "Pages" ||
          x.name === "Assessments" ||
          x.name === "Users"||
          x.name === "Help Center"||
          (currentUser.report_access == true && x.name === "Results")||
          (currentUser.report_access == true && x.name === "Learning Plan" && LearningPlans)
      );
    } else if (
      currentUser.roleName === "team-member"
    ) {
      routes1 = routes.filter(
        (x) =>
          x.name === "Dashboard" ||
          x.name === "Pages" ||
          x.name === "Assessments"||
          x.name === "Help Center"||
          (currentUser.report_access == true && x.name === "Results")||
          (currentUser.report_access == true && x.name === "Learning Plan" && LearningPlans)
      );
    } else if (currentUser.roleName === "company") {
      routes1 = routes.filter(
        (x) =>
          x.name === "Teams" ||
          x.name === "Dashboard" ||
          x.name === "Pages" ||
          x.name === "Users" ||
          x.name === "Assessments" ||
          x.name === "Help Center"
      );
    } else if (currentUser.roleName === "partner") {
      routes1 = routes.filter(
        (x) =>
          x.name === "Clients" ||
          x.name === "Dashboard" ||
          x.name === "Pages" ||
          x.name === "Users" ||
          x.name === "Teams" ||
          x.name === "Assessments"||
          x.name === "Help Center" ||
          x.name === "Settings" 
      );
    } else {
      routes1 = routes.filter(
        (x) => x.name !== "Shop"&&
        x.name !== "Results"
      );
    }
  }
  if (company) {
    currentCompany = company;
  }

  return (
    <nav
      className={
        currentUser || currentCompany
          ? "sidebar" +
          (!sidebar.isOpen ? " toggled" : "") +
          (sidebar.isSticky ? " sidebar-sticky" : "")
          : ""
      }
    >
      
      {currentUser || currentCompany ? (
        <div className="sidebar-content sidebar" style={{position:"fixed",height:'100%'}}>
          { (window.location.href.indexOf("AssessmentQuiz") > -1 || window.location.href.indexOf("ReviewAssessment") >-1 ||window.location.href.indexOf("ReSubmitQuestionOption")> -1)&& 
            <div style={{position:"absolute",top:"0",bottom:"0",left:"0",right:"0",zIndex:"99"}}></div>
          }    
          <PerfectScrollbar>
            <a className="sidebar-brand" href="/">
              {
                PartnerLogo == "" ? '' : <img  src={PartnerLogo} alt="sga" width="100%" />
              }
            </a>
            <a className="sidebar-brand" href="/">
              <img className="" src={logo} alt="sga" width="100%" />
            </a>
            <div>
               <ul className="sidebar-nav mt-4">
              {routes1.map((category, index) => {
                return (
                  <React.Fragment key={index}>
                    {/*{category.header ? (
                                        <li className="sidebar-header">{category.header}</li>
                                    ) : null}*/}

                    {category.children ? (
                      <SidebarCategory
                        name={category.name}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={openRoutes[index]}
                        onClick={() => toggle(index)}
                      >
                        {category.children.map((route, index) => (
                          <SidebarItem
                            key={index}
                            name={route.name}
                            to={route.path}
                            badgeColor={route.badgeColor}
                            badgeText={route.badgeText}
                          />
                        ))}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem
                        name={category.name}
                        to={category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                      />
                    )}
                  </React.Fragment>
                );
              })}
              <SidebarLinkItem
                name={'Glossary'}
                badgeText={'Glossary'}
              />
            </ul>
            </div>
           

            {!layout.isBoxed && !sidebar.isSticky ? (
              <div className="sidebar-bottom d-none d-lg-block">
                <div className="media">
                  <img
                    className="rounded-circle mr-3"
                    src={avatar}
                    alt="Chris Wood"
                    width="40"
                    height="40"
                  />
                  <div className="media-body">
                    <h5 className="mb-1">
                      {currentCompany === null
                        ? currentUser.FirstName
                        : currentCompany.CompanyName}
                    </h5>
                    <div>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="text-success"
                      />{" "}
                      Online
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </PerfectScrollbar>
        </div>
      ) : (
        <div></div>
      )}
    </nav>
  );
};

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    layout: store.layout,
  }))(Sidebar)
);
