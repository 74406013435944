import React, { useState, useEffect } from "react";

// Components
import { Container } from "reactstrap";
import { Card, CardBody, Media, Table, Progress, Row, Col } from "reactstrap";
import { Pagination } from 'antd';

// Services
import AuthService from "../../../services/auth.service.js";
import assessmentService from "../../../services/assessment.service.js";
import dayjs from 'dayjs';
// Assets
import dashboard_1 from "../../../assets/img/photos/dashboard_1.png";
import dashboard_2 from "../../../assets/img/photos/dashboard_2.png";
import dashboard_3 from "../../../assets/img/photos/dashboard_3.png";
import resultdashboard_1 from "../../../assets/img/icons/resultsIcon.svg";




/*
* DASHBOARD
*
* The following dashboard will include
*
* Welcome Message - (COMPLETED)
* Credit Balance - (COMPLETED)
* TODO: Show current status of the Assessments (Eg: open, pending, completed) - (OPEN)
* TODO: Latest Results of the final assessment completed - (OPEN)
*
* */

const ResultDsahboard = (props) => {
    // Get current user from local storage if exist
    var currentUser = AuthService.getCurrentUser();

    // Get current user company from local storage if exist
    var company = AuthService.getCurrentCompany();

    // Push user out to the login page if login session not found
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    sessionStorage.removeItem('results');
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(12);
    const [allAssessments,setAllAssessments]= useState([]);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const [assessmentDetails, setAssessmentDetails] = useState([]);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    useEffect(() =>{
        var assessmentList = assessmentService.GetAllAssessmentList(currentUser.id);
            var details = assessmentList
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    for(let i = 0; i < data.length - 1; i ++) {
                        for(let j = 0; j < data.length - 1 - i; j ++) {
                            if(data[j].EndTime == ''){
                                if(data[j].Status == 'COMPLETED'){
                                    if(data[j+1].EndTime !== ''){
                                        const temp = data[j];
                                        data[j] = data[j+1];
                                        data[j+1] = temp;
                                    }
                                }else{
                                    const temp = data[j];
                                    data[j] = data[j+1];
                                    data[j+1] = temp;
                                }
                            } else{
                                if(dayjs(data[j].EndTime) < dayjs(data[j+1].EndTime)) {
                                    const temp = data[j];
                                    data[j] = data[j+1];
                                    data[j+1] = temp;
                                }
                            }
                        }
                    }
                    setAllAssessments(data.filter(x => x.UserId == currentUser.id));
                })
                .catch((e) => {
                    console.log(e);
                });
        const assessmentDetail = () => {
            var assessmentDetailList = assessmentService.GetAllAssessmentDetails();

            var details = assessmentDetailList
                .then((response) => {
                    return response;
                })
                .then((data) => {
                       
                    setAssessmentDetails(data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        assessmentDetail()
    },[])
        // Handler for starting an assessment
        const handleStartAssesmentClk = (Schema) => {
            var downloadUrl =  Schema.ReportLink;
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
        }

    return (
        <div id="dashboard_div">
            <Container fluid className="p-0">
                <Row className="mb-2 mb-xl-4">
                    <Col xs="auto" className="d-none d-sm-block">
                        <h2>Welcome to your Result Dashboard</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} xl={3}>
                        <Card className="flex-fill bg-primary text-white mb-0" style={{ height: '89%', overflow: "hidden" }}>
                            <CardBody>
                                <Media>
                                    <Media body>
                                        <h5 className="mb-2 text-white">Congratulations, </h5>
                                        <h3 className="text-white">{currentUser.FirstName}</h3>
                                        <div className="mb-0">
                                            Here are your results
                                        </div>
                                    </Media>
                                    <img src={resultdashboard_1} width='40%' alt="" className=" " />
                                </Media>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row >
                    {allAssessments.slice(indexOfFirstPost, indexOfLastPost).map((item, i) => {
                        return(
                            <Col xs={12} md={6} xl={3} key={i} className={item.Status == 'COMPLETED' ? 'mt-3':'disableCard mt-3'}>
                                <Card className="flex-fill mb-0" style={{ height: '100%'}}>
                                    <CardBody className="py-4" style={{display: 'flex',flexDirection: 'column',justifyContent: 'space-between'}}>
                                        <div style={{ height: '6rem'}}>
                                        <img src={item.AssessmentIcon} style={{ height: '5rem'}}  alt="AssessmentIcon" />
                                        </div>
                                        <p style={{fontSize:'20px',fontWeight:'700',margin:'0'}}>{item.AssessmentName}</p>
                                        <p style={{color:'#93D3A2',fontSize:'16px',fontWeight:'600',cursor:'pointer'}} className={item.ReportLink =='' ? 'disableCard':''} onClick={()=>{ handleStartAssesmentClk(item)}}>{item.Status == 'COMPLETED' && item.ReportLink ==''?'PENDING...':'VIEW RESULTS'}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
            <Pagination 
                defaultPageSize={12}
                showSizeChanger={false}
                onChange={paginate}
                defaultCurrent={1}
                current={currentPage}
                total={allAssessments.length}
                style={{margin:'1rem 0'}}
            />
        </div>
    );
};
export default ResultDsahboard;
