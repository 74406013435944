import React, {useEffect} from "react";

// Components
import {Link, Redirect} from "react-router-dom";
import {
    Card,
    CardBody,
    Col
} from "reactstrap";

const ThankYou = (props) => {
    useEffect(()=>{
        setTimeout(()=>{
            props.history.push("/");
            window.location.reload()
        }, 10000)
        return () =>{
            sessionStorage.removeItem('companLogoIfo')
        }
    })
    return (
        <React.Fragment>
            <Col sm="10" md="10" lg="10" className="mx-auto d-table h-100">
                <Card>
                    <div className="signup-othercard">
                        <CardBody>
                            <div className="m-sm-4">
                                <div className="text-center">
                                    <h1 style={{fontWeight:"bolder",fontSize:"50px"}}>Thank You</h1>
                                    <p className="lead" style={{color: '#007BFF',fontWeight: '500'}}>
                                        Thank you joining the Skillsgap Platform,<br/>
                                        you will receive an email to confirm your account
                                    </p>
                                    <img src={require('../../assets/img/logo/TankyouIMG.png').default} style={{width: '25%',marginTop: '4%'}} alt="" />
                                </div>

                            </div>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default ThankYou;