/*2022-9-30-2022-10-7*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import companiesService from "../../services/companies.service.js";
import './css/LandingPage.css'
import step1 from "../../assets/img/landingPageImg/step1.jpg";
import step2 from "../../assets/img/landingPageImg/step2.jpg";
import step3 from "../../assets/img/landingPageImg/step3.jpg";
import step4 from "../../assets/img/landingPageImg/step4.jpg";
import step5 from "../../assets/img/landingPageImg/step5.jpg";
import footerImg from "../../assets/img/landingPageImg/footerImg.png";

const LandingPage = (props) => {
  let CompanyPageInfo = companiesService.getCompanyPageInfo();
  const companyPrimaryColor = CompanyPageInfo.colour_1 ?? '#000';

  const buttonColor = {
    backgroundColor: companyPrimaryColor,
    color: '#fff'
  }
  const BannerBackground = {
    background: `url(${CompanyPageInfo.banner_image}) no-repeat`,
    backgroundSize: 'cover',
    position: 'relative',
    minHeight: "400px"
  }
  const textColor = {
    color: companyPrimaryColor
  }
  const backgroundColor = {
    backgroundColor: companyPrimaryColor
  }

  return (
    <React.Fragment>
      <div className="col-12 px-0">
        <div className="bannerBody d-flex align-items-center w-100" style={BannerBackground}>
          <div className="container">
            <div className="bannerContent mx-5">
              <p>{CompanyPageInfo.banner_text}</p>
              <Link to={'/' + CompanyPageInfo.company_page_url + '/login'} style={buttonColor} className="btn">
                Login
              </Link>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <h1 className="text-center display-5" style={textColor}>{CompanyPageInfo.section_1_title}</h1>
          <p className="w-50 text-center mx-auto">
            {CompanyPageInfo.section_1_body}
          </p>
        </div>
        <div className="row align-items-center justify-content-center mx-0">
          <div className="col-auto">
            <img src={CompanyPageInfo.logo} className="mr-5" alt="" style={{ height: '100px' }} />
          </div>
          <div className="col-auto">
            <img src={CompanyPageInfo.client_additional_logo} alt="" className="mr-5" style={{ height: '100px' }} />
          </div>
        </div>

        <div className="stepBody flexRow mt-4 py-5" style={backgroundColor}>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="step p-3 bg-white">
                  <div className="stepTittle">Step1</div>
                  <p className="text-center">Login and complete your assessments</p>
                  <img className="stepImg" alt="" src={step1} />
                </div>
              </div>
              <div className="col">
                <div className="step p-3 bg-white">
                  <div className="stepTittle">Step2</div>
                  <p className="text-center">Review your detailed personalised reports and discuss with your manager.</p>
                  <img className="stepImg" alt="" src={step2} />
                </div>
              </div>
              <div className="col">
                <div className="step p-3  bg-white">
                  <div className="stepTittle">Step3</div>
                  <p className="text-center">Access your high-impact e-Learning program specifically designed for you in your role.</p>
                  <img className="stepImg" alt="" src={step3} />
                </div>
              </div>
              <div className="col">
                <div className="step p-3 bg-white">
                  <div className="stepTittle">Step4</div>
                  <p className="text-center">Integrate your learning into your work week.</p>
                  <img className="stepImg" alt="" src={step4} />
                </div>
              </div>
              <div className="col">
                <div className="step p-3 bg-white">
                  <div className="stepTittle">Step5</div>
                  <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  <img className="stepImg" alt="" src={step5} />
                </div>
              </div>
            </div>


          </div>
        </div>

        <div className="body_end_text">
          <p className="h1 font-weight-bold text-center mt-5 w-75 mx-auto" style={textColor}>
            {CompanyPageInfo.body_end_text}
          </p>
        </div>
        <div className="Footer p-5">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-auto">
                <div className="footerHrefBody">
                  <Link to='/'>Home</Link><br />
                  <a href="https://comprara.com.au" target="_blank" rel="noopener noreferrer">About Comprara</a><br />
                  <a href="https://skillsgapanalysis.com/" target="_blank" rel="noopener noreferrer">About SkillsGAP Analysis</a><br />
                  <a href="https://academyofprocurement.com/" target="_blank" rel="noopener noreferrer">About Academy of Procurement</a><br />
                  <a href="https://skillsgapanalysis.com/terms/" target="_blank" rel="noopener noreferrer">Terms of Use</a><br />
                  <a href="https://skillsgapanalysis.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </div>
              </div>
              <div className="col-auto">
                <img className="footerImg mx-auto" alt="" src={footerImg} />
                <div className="poweredText text-center">Powered by Comprara</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

export default LandingPage;
